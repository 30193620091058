<template>
  <div class="container flex flex-col justify-center mx-auto my-3">

    <!-- <iframe src="http://localhost:8080/calculators/c-20220528.023605474-e3d-02a44b-5fa9a0"
      width="100%" height="300" style="border:1px solid black;">
    </iframe> -->

    <div id="help">
      <div class="text-2xl font-bold text-center p-4">Help</div>
      <div class="flex flex-row justify-around">
        <ul class="list-decimal mx-8 space-y-2">
          <li>Click on
            <button class="round-btn-full p-1 w-7 h-7">
              <img src="@/assets/icons/search-24px.svg"/>
            </button> to see available calculations in the cloud library.
          </li>

          <li class="inline-block">Click on
            <button class="round-btn-full text-white p-1 w-7 h-7">
              <img src="../assets/icons/public_black_24dp.svg"
                alt="Public"
                height="20" width="20"/>
            </button> or
            <button class="round-btn-full text-white p-1 w-7 h-7">
              <img src="../assets/icons/private_black_24dp.svg"
                alt="Private"
                height="20" width="20"/>
            </button> to switch between "Public" (ie: public calcs created and shared by all users) and "Private" (ie: calcs set as "PRIVATE" by the owner) contents.
          </li>

          <li>Click on
            <button class="roundBtn">
              <img src="@/assets/icons/add_circle_outline_black_24dp.svg"
                height="20" width="20" />
            </button> or press
            <kbd>Alt</kbd>+<kbd>Q</kbd> to add new
            <u>Q</u>uantity / Expression. There are 6 types of quantity and expression are supported in the Quantity/Expression field to allow complicated calculations to be created.
            <a href="https://v2.donwen.com/calculators/c-20231019.031853296-e3d-0cd49d-54e913/">
              <img class="border-4 border-light-blue-500" src="@/assets/images/Box-Volume-and-Mass-Calculation-with-Multi-value-Inputs.png" alt="Top-Down Demo">
            </a>
            <div class="text-center underline">
              Click on the image to run the above calculation.
            </div><br>
            <ul>
              <li>(1) <b>Number (for PQ)</b>: <br>
                <div><i>
                  See Row 4 in the above image. <br><br>
                  When a vaild number is entered, the corresponding variable symbol can be used in other expressions. <br><br>

                  When a unit is set for a number input for the first time, the app will create a Physical Quantity (hereinafter as PQ) for the [number, unit] set;
                  and the unit dropdown list will show only units of the same category; the user can then switch between different units of the same PQ. In other words,
                  once a PQ is created, changing its unit will trigger its value change in the input field, but the PQ will stay the same.<br><br>

                  Eg: if you change the unit of '2 m' to 'cm', the number field will be changed automatically to 200; it is the same PQ shown in 2 different units,
                  be it 2 m or 200 cm, as two different measurements.<br><br>

                  When number is changed by the user, it means the user has changed the input, thus the PQ will be updated to the new [number, unit] set.<br><br>
                  Eg: if you change the number of '2 m' to '3', a new PQ will be created to using the new input number and the current unit, ie: '3 m'.<br>
                </i></div><br>
              </li>

              <li>(2) <b>Multi-Value Numeric Input (for PQm)</b>: <br>
                <div><i>
                  See Row 1-3 in the above image. <br><br>
                  In the expression field, enter multi-value input using '|' as delimiter, eg: <u>1|2|3</u>. <br><br>

                  Upon completion of the input for the first time, the expression will be converted to a dropdown list with an Editing button
                  <button
                    class="btn-unitlist mx-1 rounded-full hover:bg-blue-400 focus:outline-none w-6 h-6 items-center justify-center"
                    @click="toggleEditing">
                    <label title="edit...">
                      <img style="display: inline;"
                        src="@/assets/icons/edit_FILL0_wght400_GRAD0_opsz24.svg"
                        alt="Show Help"
                        height="20" width="20" />
                    </label>
                  </button>
                  behind. The first item will be set as the default selection for the dropdown, while allowing user to make selection for the current calculation.<br><br>

                  The user can click on the Editing button
                  <button
                    class="btn-unitlist mx-1 rounded-full hover:bg-blue-400 focus:outline-none w-6 h-6 items-center justify-center"
                    @click="toggleEditing">
                    <label title="edit...">
                      <img style="display: inline;"
                        src="@/assets/icons/edit_FILL0_wght400_GRAD0_opsz24.svg"
                        alt="Show Help"
                        height="20" width="20" />
                    </label>
                  </button>
                  to make changes to the expression. If there is a selected item for the expression before, and
                  that item is still in the dropdown list, that selection will remain as the selected item. If the updated expression does not include
                  the previous selection, the app will default to the first item in the new dropdown list.<br><br>

                  Please note, all items should be numberic values for the app to run correctly.
                </i></div><br>
              </li>

              <li>(3) <b>Expression (for PE)</b>: <br>
                <div><i>
                  See Row 5-7 in the above image. <br><br>
                  A valid math expression can be accepted here. If valid and calculatable, the calculation will run and the result will
                  be updated automatically. The symbol of a valid expression can also be used in other expressions for complicated calculations.<br><br>
                  When an valid expression is entered in the Quantity/Expression field, if all its variables have numberic values (input or calculated), the expression
                  will be calculated with its result shown right below; if a unit is set, a variable PQ (hereinafter as PE for 'Physical Expression') will be created;
                  As in PQ, the unit dropdown list will only show all units of the same category; <br><br>
                  When the user switches to a different unit, the expression will not change; in stead, the result field below will be updated to a new matching value.<br><br>

                  For both Number and Expression inputs, when a unit is set for the first time, a PQ or PE will be created, the round button behind the drowdown box will
                  be changed from
                  <button class="round-btn-full hover:bg-blue-400 w-6 h-6">
                    <img src="../assets/icons/keyboard_double_arrow_down_black_24dp.svg" alt="show all categories" class="w-4 h-4"/>
                  </button> to
                  <button class="round-btn-full hover:bg-blue-400 w-6 h-6">
                    <img src="../assets/icons/keyboard_arrow_down_black_24dp.svg" alt="show current category" class="w-4 h-4"/>
                  </button> and show only units of the same category; clicking on the button again will switch back and forth between
                  unit lists of all categories and the current category.<br><br>

                  Everytime the user switches to a different unit category, the app will prompt the user to confirm.<br><br>
                </i></div>
              </li>

              <li>(4) <b>Boolean</b>: two values "true" and "false" are valid.</li>
              <li>(5) <b>Blank</b>: when no input is provided in the expression field, other expressions that refer to that symbol will not be calculated. </li>
              <li>(6) <b>Comments</b>: when the description field starts with "//", that row will be treated as a comment. </li>
            </ul>
          </li>

          <li>Click on
            <button class="roundBtn" title="Save Calculation">
              <img
                src="../assets/icons/file-save-24px.svg"
                alt="Save the Calculation to Cloud."
                height="20" width="20" />
            </button>
            to save the calculation to the cloud.
          </li>
          <li>Click on
            <button class="roundBtn" title="Save As New Calculation">
              <img
                src="@/assets/icons/save-as.64.png"
                alt="Save Calculation to Cloud As..."
                height="20" width="20" />
            </button>
            to save the calculation to the cloud as a new calculation.
          </li>
          <li>
            Click on
            <button class="roundBtn">
              <img src="@/assets/icons/cancel_black_24dp.svg"
                  alt="Delete the Quantity"
                  height="20" width="20" />
            </button>
            to remove the current quantity / expression.
          </li>
          <li>Click on
            <button class="roundBtn" title="Show Project Info">
              <img
                src="@/assets/icons/info_black_24dp.svg"
                alt="Show Project Info"
                height="20" width="20" />
            </button>
            to include / update project info.
          </li>
          <li>Click on
            <button class="roundBtn" title="Insert Images">
              <img
                src="../assets/icons/add_photo_alternate-24px.svg"
                alt="Insert Image for Calculation"
                height="20" width="20" />
            </button>
            to add images to the calculation.
          </li>
          <li>Click on
            <button class="roundBtn" title="Insert X-Y Charts">
              <img
                src="../assets/icons/chart-multiple-black-24px.png"
                alt="Add Chart"
                height="20" width="20" />
            </button>
            to add X-Y charts to the calculation. (To Come)
          </li>

          <li> Click on
            <button class="btn-unit-auto header-text" type="text" placeholder="" >
              Unit[Auto]
            </button> to switch between Unit Conversion Auto and Manual modes.
          </li>
          <li> Click on
            <button class="round-btn-full hover:bg-blue-400 w-6 h-6">
              <img src="../assets/icons/keyboard_arrow_down_black_24dp.svg" alt="show current category" class="w-4 h-4"/>
            </button> or

            <button class="round-btn-full hover:bg-blue-400 w-6 h-6">
              <img src="../assets/icons/keyboard_double_arrow_down_black_24dp.svg" alt="show all categories" class="w-4 h-4"/>
            </button>
            beside the unit selection dropdown box to switch between units list of current category and that of all categories.
          </li>
          <li> <u>Drag and Drop</u> on the number at the beginning of each quantity row to change the order of Quantities/Expressions.</li>
          <li> Click on the equal sign ("=") to show available options, including:
            <ul>
              <li>- fractional digits</li>
              <li>- removal of quantity / expression rows</li>
              <li>- hide/show the current row of PQ/PE. This allows user to show chosen quantities, thus cleaner UI to focus on inputs and outputs.</li>
            </ul>
          </li>

          <li>All symbols are case sensitive.</li>
          <li>User quantity/expression symbols need to be unique.</li>
          <li>Math function names and constant names can be used as quantity / expression symbols, so long as they are not used with brackets "( )". E.g.: "PI( )" is used for constant '𝞹', 'PI' is as a regular quantity symbol, like A, B, etc.</li>
          <li>Angles in trigonometric functions can be set as DEG/RAD.</li>
          <li>Conditional (Ternary) Expression is supported. See Sample Expressions section below.</li>
        </ul>
      </div>
    </div>

    <div id="workflows">
      <div class="text-2xl my-2 font-bold text-center p-4">Workflows</div>
      <div class="flex flex-row justify-around">
        <ul class="list-decimal mx-8 space-y-2">
          <li>
            <u> Calculator/Single Mode:</u>
            This is the default mode when the app starts, showing a Result field over an Input field.
            For clarity and simplicity, we expect our users to use the device built-in keyboards. See the Single Mode Intro.
            <div class="centered0">
              <iframe class="m-3" src="https://www.youtube.com/embed/MIR8hJxTc_Y"></iframe>
            </div>
          </li>

          <li>
            <u>Multi-step Top-Down Calculation Mode:</u>
            start with expression creating, then provide required downstream expressions and quantities as needed.
            <img class="border-4 border-light-blue-500" src="@/assets/images/top-down-demo.png" alt="Top-Down Demo">
            Click
            <button class="roundBtn toolbarItem">
              <img src="@/assets/icons/search-24px.svg" height="16" width="16" />
            </button> button or press
            <kbd>Alt</kbd>+<kbd>S</kbd>
            for more examples.
          </li>

          <li>
            <u>Multi-step Bottom-Up Calculation Mode:</u>
            start with input quantities, then create the expression that will calculate using previously defined
            quantities/expressions.
            <img class="border-4 border-light-blue-500" src="@/assets/images/bottom-up-demo.png" alt="Top-Down Demo">
            Click
            <button class="roundBtn toolbarItem">
              <img src="@/assets/icons/search-24px.svg" height="16" width="16" />
            </button> button or press
            <kbd>Alt</kbd>+<kbd>S</kbd>
            to search for more examples.
          </li>

          <li>
            <u>Top-Down and Bottom-Up Mixed.</u>
          </li>
        </ul>
      </div>
    </div>

    <div id="Samples">
      <div class="text-2xl my-2 font-bold text-center p-4">Sample Expressions / Equations</div>
      <div class="mx-8">
        <div class="grid grid-cols-help-samples gap-1 font-bold bg-gray-300 border border-black items-center">
          <div class="text-center p-1">{{ sampleHeader.expression }}</div>
          <div class="text-center p-1">{{ sampleHeader.inputs }}</div>
          <div class="text-center p-1">{{ sampleHeader.result }}</div>
          <div class="text-center p-1">{{ sampleHeader.desc }}</div>
        </div>
        <div class="border-b border-l border-r border-black"
          v-for="s in samples" :key=s.id>
          <div class="grid grid-cols-help-samples gap-1 border">
            <div class="bg-gray-200 text-blue-700 p-1">{{s.expression}}</div>
            <div class="p-1">{{s.inputs}}</div>
            <div class="bg-gray-200 text-blue-700 p-1 text-center">{{s.result}}</div>
            <div class="p-1">{{s.desc}}</div>
          </div>
        </div>
      </div>
    </div>

    <div id="math-functions">
      <div class="text-2xl my-2 font-bold text-center p-4">Usable Math Functions </div>
      <div class="flex flex-col justify-around text-center">
        <div class="flex flex-wrap justify-center px-8">|
          <div v-for="fn in mathMethods" :key="fn.name">
            <i class="text-blue-700 px-1">
              <a :href=fn.url target="_blank"> {{ fn.name }}</a>
            </i>|
          </div>
        </div>
        <div>
          <div class="font-bold text-left px-8">Note:</div>
          <ul class="list-decimal mx-8 text-left space-y-2">
            <li>
              The references on MDN are for reference only. They are for our users to understand the math functions. Math functions can be used in AutoCalcs take the same numbers and types of arguments, and generate the same outputs, except on the trigonometric functions as explained below.
            </li>
            <li>
              With AutoCalcs, input angles can be used in either DEG or RAD depending on users choice. Eg.: when unit of angle is set to DEG, cos(30) = 0.5; when it is set to RAD, cos(PI( )/6) = 0.5.
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div id="math constants">
      <div class="text-2xl my-2 font-bold text-center p-4">Usable Math Constants</div>
      <div class="flex flex-col justify-around text-center">
        <div class="flex flex-wrap justify-center">|
          <div v-for="prop in mathProps" :key="prop.name">
            <i class="text-blue-700 px-1">
              <a :href=prop.url target="_blank"> {{prop.name}} </a>
            </i> |
          </div>
        </div>
        <div>
          <div class="font-bold text-left px-8">Note:</div>
          <ul class="list-decimal mx-8 text-left space-y-2">
            <li>
              Suffix the constant name with brackets "( )". Eg: PI(), or E(). The constant names can be used as quantity or expression symbols when not suffixed with brackets.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="refs">
      <div class="text-2xl my-2 font-bold text-center p-4">References </div>
      <div class="flex flex-row text-center px-3">|
        <a class="px-3 text-blue-700" :href=prefix target="_blank">MDN Math Documents</a> |
        <a class="px-3 text-blue-700" href="https://www.w3schools.com/js/js_math.asp">W3Schools Math Ref</a>|
      </div>
    </div>

    <div id="utils-pub-functions">
      <div class="text-2xl my-2 font-bold text-center p-4">AutoCalcs Functions </div>
      <div class="flex flex-col justify-around text-center">
        <div class="flex flex-wrap justify-center px-8">|
          <div v-for="fn in utilsPubFuncsList" :key="fn">
            <i class="text-blue-700 px-1">
              {{ fn }}
            </i>|
          </div>
        </div>
        <div>
          <div class="font-bold text-left px-8">Note:</div>
          <ul class="list-decimal mx-8 text-left space-y-2">
            <li>
              These are AutoCalcs custom functions. Some are created as shortcuts for frequently used
              unit conversion and are not needed for unit Conversion unified calculations. They may be handy
              to be used in Single-Mode or in Multi-Step Mode when unit conversion is set as Manual.
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div id="unit-categories">
      <div class="text-2xl my-2 font-bold text-center p-4">Units & Categories</div>
      <div class="text-1xl text-center p-4">(Hover on the unit for simple description when available.)</div>

      <div class="mx-8">
        <div class="grid grid-cols-help-unit-categories gap-1 font-bold bg-gray-300 border border-black items-center">
          <div class="text-center p-1">ID<br>
            <span class="text-center p-1 text-blue-700">[Of {{ categoryCount }}]</span>
          </div>
          <div class="text-center p-1">Unit Category</div>
          <div class="text-center p-1">Dimension</div>
          <div class="text-center p-1">Units</div>
          <div class="text-center p-1">Unit Counts<br>
            <span class="text-center p-1 text-blue-700">[Of {{ unitCount }}]</span>
          </div>
        </div>

        <div class="border-b border-l border-r border-black"
          v-for="(cat, index) in UomCategories" :key=cat.id>
          <div class="grid grid-cols-help-unit-categories gap-1 border">
            <div class="bg-gray-200 text-blue-700 p-1 text-center">{{ index+1 }}</div>
            <div class="p-1">{{ cat.description }}</div>
            <div class="p-1">[{{ cat.dimension.footprint() }}]</div>
            <div class="bg-gray-200 text-blue-700 p-1 text-left">
              <ul>
                <li v-for="(unit, index) in cat.units" :key="index"
                   :title="unitDescription(unit)">{{ unit }}
                </li>
              </ul>
            </div>
            <div class="text-center">{{ cat.units.length }}</div>
          </div>
        </div>

        <div class="grid grid-cols-help-unit-categories gap-1 font-bold bg-gray-300 border border-black items-center">
          <div class="text-center p-1">ID<br>
            <span class="text-center p-1 text-blue-700">[Of {{ categoryCount }}]</span>
          </div>
          <div class="text-center p-1">Unit Category</div>
          <div class="text-center p-1">Dimension</div>
          <div class="text-center p-1">Units</div>
          <div class="text-center p-1">Unit Counts<br>
            <span class="text-center p-1 text-blue-700">[Of {{ unitCount }}]</span>
          </div>
        </div>

        <div>
          <div class="font-bold text-left px-8"><br>Notes:</div>
          <p>
            As <i class="text-blue-700 px-1"> "lb" </i> can be a unit of mass or weight in different unit systems, like US customary, Imperial, English Engineering, or British Gravitational. In AutoCalcs, we adopt the following conventions:
          </p>
          <ul class="list-decimal mx-8 text-left space-y-2">
            <li>Mass:             <i class="text-blue-700 px-1">lbm</i> <br>
            Note: <i class="text-blue-700 px-1">pound</i> is not used in AutoCalcs to avoid confusion.
            </li>
            <li>Density:          <i class="text-blue-700 px-1">lbm/ft³</i></li>
            <li>Flowrate:         <i class="text-blue-700 px-1">lbm/s</i></li>
            <li>Force:            <i class="text-blue-700 px-1">lb</i> or
                                  <i class="text-blue-700 px-1">lbf</i>
            </li>
            <li>Energy / Torque:  <i class="text-blue-700 px-1">lb-ft</i></li>
            <li>Power:            <i class="text-blue-700 px-1">lb-ft/s</i></li>
            <li>Pressure / Stress:<i class="text-blue-700 px-1">psi (lb/in²)</i></li>
          </ul> <br/>

          <p>
            You might observe that <i class="text-blue-700 px-1"> "rpm" </i> (for <i class="text-blue-700 px-1">Revolutions/Minute</i>)
            appears under both the Angular Velocity and Frequency categories.  <br/> <br/>
            To distinguish between the two, they are labeled as <i class="text-blue-700 px-1"> "rpm[rev/min]" </i> for Angular Velocity and
            simply <i class="text-blue-700 px-1"> "rpm" </i> for Frequency. While this might appear confusing at first, in the context of
            Angular Velocity, <i class="text-blue-700 px-1"> "rpm" </i> denotes how fast something rotates, while in the context of
            Frequency, <i class="text-blue-700 px-1"> "rpm" </i> denotes "cycles/min" for how often a whole revolution is made per minute.
            This representation is used for convenience, especially since <i class="text-blue-700 px-1"> "rpm" </i> is frequently employed
            in rotation related calculations like Torque vs. Power calculations. <br/> <br/>
            Therefore, in physics, depending on the context, <i class="text-blue-700 px-1"> "rpm" </i> can signify two distinct concepts.
          </p><br/>

          <p>
            Feel free to contact us if you would like to suggest more categories and/or units.
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mathMethodListFull, mathPropListFull, UomCategories, Uoms, utilsPubFuncsList } from '../API/pq'
export default {
  name: 'E3dHelp',
  props: {
    msg: String
  },
  setup () {
    const prefix = 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math'
    const mathMethods = mathMethodListFull.map(m => {
      return { name: m, url: `${prefix}/${m}` }
    })

    const mathProps = mathPropListFull.map(p => {
      return { name: p, url: `${prefix}/${p}` }
    })

    const utilsPubFuncs = utilsPubFuncsList.map(m => {
      return { name: m }
    })

    const sampleHeader = {
      expression: 'Expression',
      inputs: 'Input Quantities',
      result: 'Result',
      desc: 'Description'
    }

    const unitCount = UomCategories.map(cat => cat.units.length).reduce((a, b) => a + b, 0)
    const categoryCount = UomCategories.length

    const unitDescription = unit => {
      const uom = Uoms.find(uom => uom.unit === unit)
      return uom.description ? uom.description : null
    }

    const samples = [
      {
        id: 1,
        expression: 'y = 2 * (12 / (1 + 2))',
        inputs: 'None',
        result: 8,
        desc: 'Simple math expression.'
      },
      {
        id: 2,
        expression: 'y = 2 * {12 / (1 + 2)}',
        inputs: 'None',
        result: 8,
        desc: '{ } and ( ) can be used alternatively for enhanced legibility. We recommend using ( ) for function/constant names.'
      },
      {
        id: 3,
        expression: 'y = 4 * sin(PI( ) / 6)',
        inputs: 'None',
        result: 2,
        desc: 'sin( ) is Math function, PI( ) is Math constant 𝞹.'
      },
      {
        id: 4,
        expression: 'y = sqrt(3**2+4**2)',
        inputs: 'None',
        result: 5,
        desc: 'sqrt( ) is Math function.'
      },
      {
        id: 5,
        expression: 'y = x ^ z',
        inputs: 'x = 2, z = 3',
        result: 8,
        desc: 'Calculate x to the power of z. Here "^" is the exponential operator.'
      },
      {
        id: 6,
        expression: 'y = x ** z',
        inputs: 'x = 2, z = 3',
        result: 8,
        desc: 'Calculate x to the power of z. Here "**" is the exponential operator, it is easy to type on mobile UI.'
      },
      {
        id: 7,
        expression: 'y = pow(x, z) ',
        inputs: 'x = 2, z = 3',
        result: 8,
        desc: 'Calculate x to the power of z. Here pow( ) is a Math function.'
      },
      {
        id: 8,
        expression: 'y = a ? b0 : b1',
        inputs: 'a = true, b0 = 10, b1 = 20',
        result: 10,
        desc: 'Conditional (Ternary) Expression: evaluate y to b0 if a is true; otherwise, evaluate y to b1.'
      },
      {
        id: 9,
        expression: '(z=) 3 + a',
        inputs: '(a=) 20 (cm)',
        result: '(z=) 3.2 (m)',
        desc: `The above expression will not always be calculated correctly when the unit of 'a' or 'z'
        changes. Don't mix numbers with variables or expressions in summation when the independent
        quantity has a unit. Instead, assign a separate quantity to the number, eg: 'x=3 in', then
        the expression of 'z' becomes 'z=x+a' and can be calculated correctly for given units. \r\n
        From dimenional analysis perspective, when a number and a variable are used in summation expression,
        the number is dimensionless and the variable is dimensioned, the expression is invalid.`
      }
    ]

    return {
      mathMethods, mathProps, prefix, samples, sampleHeader,
      UomCategories, unitCount, categoryCount, unitDescription,
      utilsPubFuncsList, utilsPubFuncs
    }
  }
}
</script>

<style src="../styles/components/index.css" scoped></style>
<style scoped>
  .container { /* https://www.freecodecamp.org/news/how-to-center-anything-with-css-align-a-div-text-and-more/ */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid-help {
    display: grid;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    grid-template-columns: minmax(auto, 600px);
    border-radius: 10px;
    width: 80%;
  }

  .section {
    text-align: left;
    width: 80%;
  }

  .section-centered {
    text-align: center;
    width: 80%;
  }

  .btn-unit-auto {
    cursor: pointer;
    height: 40px;
    color: black;
    background-color: #8adcde;
    @apply rounded-full font-semibold hover:bg-blue-300;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .grid-help {
      display: grid;
      justify-content: center;
      vertical-align: middle;
      align-items: center;
      grid-template-columns: minmax(auto, 400px);
      border-radius: 10px;
      width: 80%;
    }

    .main-desc {
      width: 80%;
      overflow: hidden;
    }
  }

</style>
