/* eslint-disable */
/* eslint-disable-next-line no-unused-vars */

"use strict"

import { UomCategories, Uoms, Units } from './uom.data'
import * as utilsPub from './utils-public'

export { UomCategories, Uoms, Units }

/***
 * https://stackoverflow.com/questions/2257993/how-to-display-all-methods-of-an-object
 * get all methods from an Object, like Math
 */
/* const getAllMethods = (obj) => Object.getOwnPropertyNames(obj).filter((prop) => typeof obj[prop] === 'function')
const getAllProperties = (obj) => Object.getOwnPropertyNames(obj).filter((prop) => typeof obj[prop] !== 'function')
 */

/**
 * Get rid of whitespace? =>whitespace can be used as splitter for 2 sin()
 * May change so '()' is used for funcs and grouping; while '{}' is for grouping only.
 * '[]' is reserved for future use as array index.
 *
 *
 */
export const normalizeExpression = (expr) => {
  console.log(`@normalizing: ${expr}`)
  return !isNaN(expr) ? expr : expr.replace(/\s+/g, '')
                                   .replace('𝞹', 'PI()')
                                   .replace('{', '(')
                                   .replace('}', ')')
}

// https://www.sohamkamani.com/javascript/enums/
// usage: PqTypes.COMMENT, PqTypes.BLANK_INPUT, etc
export const PqTypes = Object.freeze({
  HEADER:                   Symbol('Header'),
  COMMENT:                  Symbol("Comment"),
  BLANK_INPUT:              Symbol("Blank Input"),
  NUMBER:                   Symbol("Number"),
  BOOLEAN:                  Symbol("Boolean"),
  MULTIVALUE_INPUT_NUMBER:  Symbol("Multivalue Input Number"),
  MULTIVALUE_INPUT_TEXT:    Symbol("Multivalue Input Text"),
  MULTIVALUE_OUTPUT:        Symbol("Multivalue Output"),
  EXPRESSION:               Symbol("Expression"),
})

// console.log(`e3d@PQ=${JSON.stringify(E3d.deg2rad(180))}`); // Checked import of E3d correctly.
// console.log(`E3d is: ${typeof E3d}`)
// console.log(`E3d is: ${JSON.stringify(E3d)}`)
// const e3dFuncsList0 = getAllMethods(E3d);
// console.table(`E3d funcs: ${e3dFuncsList0}`)

/**
 * eg:
 * 1. An input PQ: F = 100 N
 * 2. An output PQ: P = F/A + 2 * cos(theta)
 *    note: the above expression is just to describe the issue.
 *
 * Make sure expressions don't include numbers of scientific format, like
 * 1E3. Such kind of numbers should be in a separate PQ.
 *
 * InternalExpression is going to be built from the calling code where all
 * details of input PQs will be provided.
 */

export class PhysicalQuantity {
  /**
   * To Change to use object destructor, refer to CJ's video.
   * @param {*} pq
   */
  constructor (pq = {} /* {name, symbol, expression, unit} */) {
    // console.log(`pq@pq.js start: ${JSON.stringify(pq)}`)
    // this.id = new Date().toISOString().slice(0, 30); // may not be unique
    this.id = pq.id ? pq.id : new Date().getUTCMilliseconds() + Math.random()
    /* this.id = this.count;
    this.count ++; */
    this.name = pq.name || ''
    this.symbol = pq.symbol || ''
    this._unit = pq.unit || ''

    this._expression = pq.expression ? pq.expression : ''
    // console.log(`@PQ.vue: expression before normalizing: ${this._expression}`)

    this._baseExpression = this._expression // default initial expression
    /* if (this.pqType === PqTypes.MULTIVALUE_INPUT_NUMBER && this.unit) {
      this.baseExpression = expr // can the setter be used like this???
    } */

    this._value = '' // default value, property to keep state
    this._baseValue = '' // default

    this._pqType = PqTypes.BLANK_INPUT // initial

    this._baseUnit = '' // inital value

    this.message = (pq.message === undefined) ? '' : pq.message
    this.isExpressionValid = (pq.isExpressionValid === undefined) ? false : pq.isExpressionValid
    this.showAlert = (pq.showAlert === undefined) ? false : pq.showAlert
    this.showPQOptions = (pq.showPQOptions === undefined) ? false : pq.showPQOptions
    /*
      https://math.stackexchange.com/questions/64042/what-are-the-numbers-before-and-after-the-decimal-point-referred-to-in-mathemati
        Eg:  number -2.3
            "Integer digits" = (-)2 "Fractional digits" = (-).3
      It also relates to 'decimal places' per https://en.wikipedia.org/wiki/Significant_figures
     */
    this.fractionalDigits = (pq.fractionalDigits === undefined) ? 3 : pq.fractionalDigits

    this.unitConversionAuto = (pq.unitConversionAuto === undefined) ? false : pq.unitConversionAuto
    this.showPQRow = (pq.showPQRow === undefined) ? true : pq.showPQRow

    // console.log('PhysicalQuantity created')
  }

  /*
    static increaseCount() { this.count += 1 }
    static getCount() { return this.count }
  */

  /**
   * Make sure this.Value is set after this.expression is evaluated.
   * Note: side effect on _value.
   */
  set expression(v) {
    this._expression = normalizeExpression(v)

    // Side effect: to set this._value
    let expr = this._expression
    let value
    if (expr === '') { value = ''; }                  // for blank inputs
    else if (!isNaN(expr)) { value = Number(expr); }  // for number inputs
    else if (this._pqType === PqTypes.MULTIVALUE_INPUT_NUMBER) {   // for MULTIVALUE_INPUT_NUMBER
      const options = expr.split('|').filter(Boolean);
      value = options.includes(`${pq.value}`) ? pq.value : Number(options[0]);
    }
    this._value = value
  }

  get expression() { return this._expression }

  /**
   * @param {} v : current expression value
   * Phased out setter.
   */
  /* set baseExpression(v) { // for MULTIVALUE_INPUT_NUMBER type only
    if (!v.toString().includes('|')) this._baseExpression = v // for SINGLEVALUE_INPUT_NUMBER, redundant
    else { // for MULTIVALUE_INPUT_NUMBER
      this._baseExpression = v.split('|').filter(Boolean).map(
        i => `${this.fv2bv(+i)}`
      ).join('|')
    }
  } */

  get baseExpression () {
    if (!this._expression.toString().includes('|')) this._baseExpression = this._expression // for SINGLEVALUE_INPUT_NUMBER, redundant
    else { // for MULTIVALUE_INPUT_NUMBER
      this._baseExpression = this._expression.split('|').filter(Boolean).map(
        i => `${fv2bv(+i, this._unit)}`
      ).join('|')
    }
    return this._baseExpression
  }

  get pqType () {
    try {
      const expr = this.expression // expression before normalization

      if (expr === 'Quantity/Expression') { this._pqType = PqTypes.HEADER }
      else if (this.name.substr(0, 2) === '//') { this._pqType = PqTypes.COMMENT }
      else if (utilsPub.isEmptyOrWhitespace(expr)) { this._pqType = PqTypes.BLANK_INPUT }
      else if (utilsPub.isNumber(expr)) { this._pqType = PqTypes.NUMBER }
      else if (utilsPub.isBoolean(expr)) { this._pqType = PqTypes.BOOLEAN }
      else if (utilsPub.isMultivalueInput(expr)) { this._pqType = PqTypes.MULTIVALUE_INPUT_NUMBER }

      // to be validated to find it is calculatable, and treated as e3dPE.
      else { this._pqType = PqTypes.EXPRESSION }

      // w/o toString(), got error: An error occurred at expression input: Cannot convert a Symbol value to a string
      // console.log(`_pqType = ${this._pqType.toString()}`)
      return this._pqType
    }
    catch (error) {
      console.error("An error occurred at expression input: " + error.message)
    }
  }

  // set pqType (v) { this._pqType = v }  /* this is not needed. */

  get value() {
    /* let expr = this.expression
    let _value
    if (expr === '') { _value = ''; }                  // for blank inputs
    else if (!isNaN(expr)) { _value = Number(expr); }  // for number inputs
    else if (this._pqType === PqTypes.MULTIVALUE_INPUT_NUMBER) {   // for MULTIVALUE_INPUT_NUMBER
      const options = expr.split('|').filter(Boolean);
      _value = options.includes(`${pq.value}`) ? pq.value : Number(options[0]);
    } */
    // for type PqTypes.EXPRESSION, the valve will be updated with setter when expression is calculated.
    return this._value
  }

  set value(v) { this._value = v }

  /**
   * faceValue can be set in 3 cases:
   * 1. inital value by constructor
   * 2. for PQ: here fv does not always follow user input
   *    2.1 when a valid unit is not available, fv = input
   *    2.2 when a valid unit is available, fv is
   *      2.2.1 user input when user changes input. fv is the new input, then bv gets updated by conversion.
   *      2.2.2 when user switches unit in the same category, bv is not changed, fv is converted from bv.
   * 3. for PE: bv is calculated from internalExpression by calculate(pe) as soon as internalExpression is calculatable.
   *    3.1 user can not change the value output field (ie.: fv) directly here. fv is calculated from bv.
   *      3.1.1 when no valid unit, prompt user to input valid unit
   *      3.1.2 when there is valid unit, fv is converted from bv.
   */
  set faceValue(v) {
    // #1: initial value by constructor

    // #2: for PQ: here fv does not always follow user input
    //    2.1 when a valid unit is not available, fv = input

    /* // #  2.2: with a valid unit
    if (!isNaN(this._expression)) { // for PQ: user input is pq.expression, ie. here v = fv = pq.expression
      //    2.2.1 user changes input
      this._value = v
      return
      //    2.2.2 user changes unit in same category
    } */

    // #  2.2.2: with a valid unit, user changes new unit in the same category

    // for PE, needs to check unit input is valid and matches input PQs
    this._value = v
  }

  get faceValue() { return this._value }

  /**
   * baseValue can be set in 3 cases:
   * 1. inital value by constructor
   * 2. for PQ: here bv always follows fv
   *    2.1 when a valid unit is not available, bv = fv, or treat as cof0=1
   *    2.2 when a valid unit is available, bv is converted from fv
   *    2.3 when user switches unit in the same category, bv is not changed
   *    2.4 when user changes input value, fv is reset, bv then get converted from fv
   * 3. for PE: bv is calculated from internalExpression by calculate(pe) as soon as internalExpression is calculatable.
   */
  get baseValue_v0() {
    // #1: initial value by constructor

    // #2a: for PQ: here bv always follows fv; including Blank
    if (!isNaN(this._expression)) { // for PQ: user input is pq.expression, ie. here v = fv = pq.expression
      this._baseValue = this._unit === '' ? Number(this._expression) : fv2bv(this._expression, this._unit)
    }

    // #2b: for PQM:
    if (this.pqType === PqTypes.MULTIVALUE_INPUT_NUMBER) { // make sure _value is updated when user changes expression input.
      this._baseValue = this._unit === '' ? Number(this._value) : this.fv2bv(this._value, this._unit)
    }

    // #2c: for PE that contains no PQ or PE
    if (!this.symbolsOfInputPQs.length) { // for PQ.expr that is independent of other PQ/PE, and can be cal'd directly
      this._baseValue = this._unit === '' ? Number(this._value) : this.fv2bv(this._value, this._unit)
    }

    // #3: for PE: bv is calculated from internalExpression by calculate(pe) as soon as
    //     internalExpression is calculatable.
    return this._baseValue // no matter whether unit is valid or not, or null
  }

  get baseValue() { return this._baseValue }

  /**
   * Used in internalExpression to update baseValue
   */
  set baseValue(v) { this._baseValue = v }

  get unit() { return this._unit }
  set unit(v) { this._unit = v }

  set unit_V0(v) {
    const unitOld = this._unit // keep a copy for rolling back
    const baseUnitOld = this._baseUnitOld
    this._unit = v
    let baseUnitNew = this.baseUnit
    this._baseUnit = this.baseUnit

    let fv, bv
    /** if it is in the same unit category (have the same base unit), then no change to
     *  baseValue. however, if I do consider pressure and stress as 2 different categories,
     *  they both have the same base unit. Then this need improvement.
     */
    if (baseUnitOld === '')
    { // 1. No previous unit. This is the case when a unit is chosen. Keep existing faceValue
      this._baseValue = this.fv2bv(this._value, this._unit)
      this.expression = this._value
      this._baseUnitOld = baseUnitNew
    }
    else if (baseUnitNew && baseUnitNew === this._baseUnitOld)
    { // 2. Not UnitLess. Category not changed, no change to baseValue
      fv = bv2fv(this._baseValue, this._unit)
      this.expression = fv
        ? fv.toString().length > 12 // this is problematic for 111000111000.111
          ? fv.toFixed(pq.fractionalDigits)
          : fv
        : "";
      this._value = fv
    }
    else (baseUnitNew !== this._baseUnitOld)
    { // 3. Both old and new are not unitless. Unit category changed, no change to faceValue or expression
      // otherwise, recalculate baseValue for a new category
      /* const res = confirm('Confirm to change unit category?')

      if (res) { // back to previous valid unit and category
        // TODO: need more work here!!! How to keep previous unit state? => Maybe use faceUnit?
        const fv = Number(pq.expression)
        pq.baseValue = fv
      } */

      let res = confirm('Confirm to change unit category?')
      if (res) {
        this._baseUnitOld = this.baseUnit
        let baseValue = this.baseValue // update baseValue based on expression and new unit.
        console.log("Unit category changed")
      } else {
        this._unit = unitOld
        this._baseUnit = this.baseUnit
        console.log("Unit not changed") // TODO: check if auto-complete get rolled back.
      }
    }
  }

  /**
   * @param {}
   * return: all categories this unit belongs to; if no unit, return empty array
   */
  categories(unit) {
    if (unit) { return UomCategories.filter(cat => cat.units.includes(unit)) }
    return []
  }

  /**
   * @param {}
   * return: all Uoms of units that are in the same category with unit; if no unit, return empty array
   */
  uoms(unit/* null for currentCategory, or category */) {
    const cat = this.categories(unit).length ? this.categories(unit)[0].name : '' // TODO: to deal with multiple categories
    if (cat) {
      return Uoms.filter(uom => {
        // console.log(`uom, id: ${uom.id}, category: ${uom.category.name}, cat: ${cat}`)
        return uom.category.name === cat
      })
    }
    return []
  }

  /**
   * find all units that are in the same category with unit
   * @param {*} unit
   */
  categoryUnits(unit) {
    if (this.uoms(unit).length) {
      return this.uoms(unit).map(uom => uom.unit)
    }
    return []
  }

  faceUom(unit) { return Uoms.find(uom => uom.unit === unit) }

  /**
   * it is set when a new unit is selected.
   */
  baseUnit(unit) { // baseUnit is set when a new valid unit is seleted by user.
    // make sure only a valid unit or '' can be passed to this setter.

    if (this.categoryUnits(unit).length) { // valid unit
      const catUnits = this.categoryUnits(unit)
      // console.log(`@setBaseUnit: categoryUnits = ${catUnits}`)
      return this.categoryUnits(unit)[0]
    } else {
      return '' // no unit or invalid unit
    }
  }

  /**
   * Not in use. Not needed.
   * @param {*} unit
   */
  baseUom(unit) {
    if (unit) { return this.uoms(unit)[0] }
    return {}
  }

  fv2bv = (fv, unit) => {
    let c0 = 1, c1 = 0, uom
    // this.categories.length checks the unit exists in the library.
    if (unit && this.categories(unit).length && this.unitConversionAuto) {
      uom = this.faceUom(unit)
      c0 = uom.cof0
      c1 = uom.cof1 ? uom.cof1 : c1
    }
    return fv * c0 + c1
  }

  bv2fv = (bv, unit) => {
    let c0 = 1, c1 = 0, uom
    if (unit && this.categories(unit).length && this.unitConversionAuto) {
      uom = this.faceUom(unit)
      c0 = uom.cof0
      c1 = uom.cof1 ? uom.cof1 : c1
    }
    return (bv - c1) / c0
  }

  /**
   * invalid symbols:
   * - 1r
   * - keywords used by my system, including Math functions and constant names
   *
   * valid symmbols:
   * - characters
   * - characters followed by numbers and / or char(s)
   */
  get isSymbolValid () {
    //
  }

  /***
   * // Getter all parts separately.
   * Expression: A / sin(alpha * 3.1416 / 180)
   * Return: [A,/,sin,(,alpha,*,3,.,1416,/,180,), count: 12]
   */
  get allPartsInExpression () {
    const regex = /\w+|\W/g
    const tmp = [...this.expression.matchAll(regex)]
    console.table(`all parts in ${this.name}: ${tmp}`)
    return tmp
  }

  /**
   * get every word other than non-word characters from output PQ
   * eg: P = F/A + 2 * cos(theta)
   * expression = 'F/A + 2 * cos(theta)'
   * return: F, A, 2, cos, theta
   *
   * in future, should exclude invalid words like 2A, etc.
   */
  get symbols () {
    const regex = /\w+/g
    /* let tmp0 = this.expression.replace(/\s+/g, '');
    console.log(`expr with no ws: ${tmp0}`);
    this.expression = tmp0; */
    const tmp = [...this.expression.matchAll(regex)]
    // console.table(`symbols in ${this.name}: ${tmp}`)
    return tmp
  }

  /**
   * get constants and multipliers in formula.
   * Eg: Y = 3 * X^2 + A4
   * Return: [3, 2] // but not the 4 in A4
   */
  get numbersInExpression () {
    const nums = this.symbols.filter(sym => !isNaN(sym))
    /* let numberPattern = /\d+/g;
    let tmp = [...this.expression.matchAll(numberPattern)]; */
    // console.log(`numbers in PQ: ${nums}`)
    return nums
  }

  /**
   * get all splitter characters in user expression
   * Eg: Y = 3 * X^2
   * Return: [*, ^]
   */
  get splitterCharsInExpression () {
    // was /\W+/g previsously, which treat multiple connected splitters as one.
    // that is acturally more effective when making the inernalExpression.
    const regex = /\W/g
    const tmp = [...this.expression.matchAll(regex)]
    // console.table(`splitterChars in ${this.name}: ${tmp}`)
    return tmp
  }

  /**
   * All symbols of inputPQs don't have '(' behind.
   * Remove all symbols that are followed by '('.
   */
  get symbolsOfInputPQs () {
    const self = this

    // 1. remove numbers from symbols
    const symbolsExcludingNumbers = this.symbols.filter(sym => isNaN(sym[0]))
    // console.log(`symbols not including numbers: ${symbolsExcludingNumbers}`)

    // 2. remove all function names
    const allFuncsRemoved = symbolsExcludingNumbers.filter(sym => {
      // const tmp = !self.allFuncsInExpression.includes(sym)
      // if found a func in expr has the same index as sym's index, then remove it.
      const tmp = self.allFuncsInExpression.findIndex(fn => fn.index === sym.index)
      return (tmp !== -1) ? false : true
    })
    // console.log(`symbols with no function names: ${allFuncsRemoved}`)

    // 3. remove booleans
    const allBooleansRemoved = allFuncsRemoved.filter(sym => {
      const tmp = self.booleansInExpression.findIndex(b => b.index === sym.index)
      return (tmp !== -1)? false : true
    })

    return allBooleansRemoved
  }

  /***
   * get all input symbols from output PQ. Eg: [F, A] in "P=F/A", in which A could be a symbol
   * of another expression.
   *
   * 0. Was trying to use regex to exclude words like 2A, 30B2, etc. Could not
   *    find AND operator in regex thus it is hard to do do.
   *    However it should be OK to exclude from above symbols() array the items
   *    that starts with digits.
   * 1.   remove numbers from symbols
   * 2.1  remove method names from Math library
   * 2.2  remove prop names from Math library
   * 3.   How to make sure float numbers be extracted properly. Like 3.0 should be
   *      read as one number.
   *      => This does not actually matter. It will be put back as it is and usable.
   * 4.   Need to get rid of whitespace as first step so error input like
   *      'F/A + 2 A * B' will be kicked out in the first place.
   */

  get symbolsOfInputPQs_R0() {
    // 1. remove numbers from symbols
    const symbolsExcludingNumbers = this.symbols.filter(sym => isNaN(sym[0]))
    console.log(`symbols not including numbers: ${symbolsExcludingNumbers}`)

    // 2.1 remove function names from Math lib
    const mathMethodsRemoved = symbolsExcludingNumbers.filter(sym => !mathMethodListFull.includes(sym[0]))
    console.log(`symbols with no Math Methods: ${mathMethodsRemoved}`)

    // 2.2 remove property names from Math lib
    const mathPropsRemoved = mathMethodsRemoved.filter(sym => !mathPropListFull.includes(sym[0]))
    console.log(`symbols with no Math Props: ${mathPropsRemoved}`)

    // 3   remove funcs from E3d module utils
    const e3dFuncsRemoved = mathPropsRemoved.filter(sym => !e3dFuncsList.includes(sym[0]))
    console.log(`symbols with no E3d utils functions: ${e3dFuncsRemoved}`)

    // 4   remove funcs from other modules / namespaces

    return e3dFuncsRemoved
  }

  get userSymbolsInExpression() {
    return 9
  }

  /***
   * get the math function names used in expression and to prefix with 'Math.' when
   * rebuild the internal expression
   */
  get mathMethodsInExpression() {
    const mathMethodsInExpression = this.symbols.filter(sym => mathMethodListFull.includes(sym[0]))
    return mathMethodsInExpression
  }

  get mathPropsInExpression() { // user will input with ()
    let self = this  // this can not be used in arrow function.
    const mathPropsInExpression0 = this.symbols.filter(sym => mathPropListFull.includes(sym[0]))
    // check if a prop is a mathProp and followed by '(' in expression.
    const mathPropsInExpression = mathPropsInExpression0.filter(p => {
      // console.log(`@mathPropsInExpression: p=${JSON.stringify(p)}, index=${p.index}, p.len=${p[0].length}`)
      // console.log(`allPartsInExpresion: ${(self.allPartsInExpression)}`)
      let charBehindIndex = p.index + p[0].length

      /**
       * // to return mathProps that follows with '('
       * and record its position so can be handled correctly when building internalExpression.
       */

      // console.log(`the char behind: ${(self.expression)[charBehindIndex]}`)
      return (self.expression)[charBehindIndex] === '(' || (self.expression)[charBehindIndex] === '{'
    })

    return mathPropsInExpression
  }

  /***
   * get the boolean symbols used in expression and to be used as is when
   * rebuild the internal expression
   */
  get booleansInExpression() {
    const booleansInExpression = this.symbols.filter(sym => booleanValues.includes(sym[0]))
    return booleansInExpression
  }

  get multiValuesInExpression() {}

  /**
   * Use regex lookahead to simplify this a bit. See regex-test.js snippet.
   * regex = /\w+(?=\()/g
   */
  get allFuncsInExpression() {
    // const regex = /\w+(?=\()/g ; // any word that is followed by with a bracket '('
    // const regex = /\w+(?=\(|\{)/g ; // any word that is followed by with a bracket '(' or '{'
    const regex = /\w+(?=[({])/g ; // any word that is followed by a bracket '(' or '{'

    // const normalizedExpr = this.normalizeExpression();
    // const funcNames = [...this.expression.matchAll(regex)]
    const funcNames = [...this.expression.matchAll(regex)]

    // console.table(`funcNames in ${this.name}: ${funcNames}`)
    return funcNames
  }

  /**
   * Get all functions in expression. Including Math lib functions, and functions in other namespace, and UDF
   * (User Defined Function)
   * An expressin may include the following types of parts
   * 1. splitter chars [+, -, *, /, (, )]
   * 2. numbers
   * 3. functions (Math methods, other namespace functions, UDFs)
   * 4. user defined PQ symbols (a, b, x2a, etc)
   *
   * eg: "A / sin(alpha * 3.1416 / 180) + PI() + PI + sin + V(L,H,W) - cos(30)"
   * return: sin, PI, V, cos
   *
   * Can use regex lookahead to simplify this a bit. See regex-test.js snippet, and above
   */
  get allFuncsInExpression_R0() { // not working yet.
    const regex = /\w+\(/g; // any word that ends with a bracket '('
    const funcBlocks = [...this.expression.matchAll(regex)]
    const funcNames = funcBlocks.map(fnBlock => {   // remove ending bracket '('
      // Object.assign(fnBlock, fnBlock[0]: fnBlock[0].slice(0, 01))
      return fnBlock
    })

    // console.table(`funcNames in ${this.name}: ${funcNames}`)
    return funcNames
  }

  /**
   * Fow now, deal with other funcs (including my lib and UDFs) here. In future, may separate per namespace
   */
  get nonMathFuncsInExpression() {
    const allFuncNames = this.allFuncsInExpression
    const nonMathFuncs = allFuncNames.filter(fn => mathNamesListFull.includes(fn))
    // console.table(`nonMathFuncs: ${nonMathFuncs}`)
    return nonMathFuncs
  }

  get e3dFuncsInExpression() {
    const allFuncNames = this.allFuncsInExpression
    const e3dFuncs = allFuncNames.filter(fn => {
      let tmp = e3dFuncsList.includes(fn[0]) // PAUSED TODO:

      return tmp
    })
    // console.table(`e3dFuncs: ${e3dFuncs}`)
    return e3dFuncs
  }

  get utilsPubFuncsInExpression() {
    const allFuncNames = this.allFuncsInExpression
    const utilsPubFuncs = allFuncNames.filter(fn => {
      let tmp = utilsPubFuncsList.includes(fn[0]) // PAUSED TODO:

      return tmp
    })
    // console.table(`e3dFuncs: ${e3dFuncs}`)
    return utilsPubFuncs
  }

  get mathTrigFunctionsInExpression() {
    const mathTrigFunctionsInExpression = this.symbols.filter(sym => mathMethodListTrig.includes(sym[0]))
    return mathTrigFunctionsInExpression
  }

  /**
   * Set the expression that can be used by eval()
   * @param {string} iExpr
   */
  set internalExpression(iExpr) { this._iExpr = iExpr }

  /**
   * Get the expression that can be used by eval()
   */
  get internalExpression() { return this._iExpr }

  /**
    create data obj to be stored in firebase.
    May use FormData() if I use Form elelment for E3dCalculator.
   */
  get dataObj() {
    return {
      id: this.id,
      name: this.name,
      symbol: this.symbol,
      expression: this.expression,
      value: this._value,
      unit: this._unit,
      fractionalDigits: this.fractionalDigits,
      showPQRow: this.showPQRow // This makes sure that showPQRow is passed on to be saved with the calc.
    }
  }

  get dataForInventor() {
    return {
      // id: this.id,
      name: this.name,
      value: this._value,
      symbol: this.symbol,
      // expression: this.expression,
      unit: this._unit,
      // fractionalDigits: this.fractionalDigits,
      // showPQRow: this.showPQRow // This makes sure that showPQRow is passed on to be saved with the calc.
    }
  }

  /**
   * This is function to be used in calling code to see intermediate results.
   */
  get test() {
    let numbersInExpression = this.numbersInExpression
    let allPartsInExpresion = this.allPartsInExpression
    let symbolsOfInputPQs = this.symbolsOfInputPQs

    let mathMethodsInExpression = this.mathMethodsInExpression
    let mathPropsInExpression = this.mathPropsInExpression
    let splitterCharsInExpression = this.splitterCharsInExpression

    console.log(`math names(methods&props) list full: ${mathNamesListFull}, count: ${mathNamesListFull.length}`)
    console.log(`math methods list full: ${mathMethodListFull}, count: ${mathMethodListFull.length}`)
    console.log(`math props list full: ${mathPropListFull}, count: ${mathPropListFull.length}`)

    console.log(`math func list no E: ${mathMethodListNoE}, count: ${mathMethodListNoE.length}`)
    console.log(`math func list no E or trigs.: ${mathMethodListNoE_n_Trig}, count: ${mathMethodListNoE_n_Trig.length}`)
    console.log(`math func list no PIE: ${mathMethodListNoPiE}, count: ${mathMethodListNoPiE.length}`)
    console.log(`numbersInExpression: ${numbersInExpression}, count: ${numbersInExpression.length}`)
    console.log(`allPartsInExpresion: ${allPartsInExpresion}, count: ${allPartsInExpresion.length}`)

    console.log(`symbolsOfInputPQs: ${symbolsOfInputPQs}, count: ${symbolsOfInputPQs.length}`)
    console.log(`mathMethodsInExpression: ${mathMethodsInExpression}, count: ${mathMethodsInExpression.length}`)
    console.log(`!!mathPropsInExpression: ${mathPropsInExpression}, count: ${mathPropsInExpression.length}`)
    console.log(`splitterCharsInExpression: ${splitterCharsInExpression}, count: ${splitterCharsInExpression.length}`)

    return mathMethodListFull
  }

  /**
   * if !isInput, find out how many inputs is for this.
   * [ ] how to exclude numbers, math functions, etc.
   */
  get pqCount() { return this.symbols.length }

  // Method
  calcArea() {  return } // template
  calculateValue() { this.value = eval(this.expression) }

}

const mathNamesListFull = Object.getOwnPropertyNames(Math) // including Math methods and properties.

/**
 * Math methods only, excluding property constants.
 * abs,acos,acosh,asin,asinh,atan,atanh,atan2,ceil,cbrt,expm1,clz32,cos,cosh,exp,floor,fround,hypot,
 * imul,log,log1p,log2,log10,max,min,pow,random,round,sign,sin,sinh,sqrt,tan,tanh,trunc, count: 35
 */
export const mathMethodListFull = utilsPub.getAllMethods(Math)

// [E, LN10, LN2, LOG10E, LOG2E, PI, SQRT1_2, SQRT2], count: 8
export const mathPropListFull = utilsPub.getAllProperties(Math)

const e3dFuncsList = utilsPub.getAllMethods(utilsPub)
// console.log(`E3d funcs: ${JSON.stringify(e3dFuncsList)}`)
// console.table(`E3d funcs: ${e3dFuncsList}`)

export const utilsPubFuncsList = utilsPub.getAllMethods(utilsPub)

/**
 * To exclude the two special constants PI and E, so they can be used by user as PQ symbols
 */
const mathMethodListNoPiE = mathMethodListFull.filter(fn => fn !== "PI" && fn !== "E") // obs

/**
 * To exclude the two special constants E, so they can be used by user as PQ symbols
 * Prefer this list over the above, since user will not likely use PI as PQ symbol.
 * However, Pi is very likely to be used as PQ symbol.
 */
const mathMethodListNoE = mathMethodListFull.filter(fn => fn !== "E") // obs

export const mathMethodListTrig = [
  "sin", "cos", "tan", /* "csc", "sec", "cot" */
  "asin", "acos", "atan", "atan2"
 ]

const mathMethodListNoE_n_Trig = mathMethodListNoE.filter(fn => !(mathMethodListTrig.includes(fn)))

export const booleanValues = [ "true", "false" ]
