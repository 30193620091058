<!--
  https://www.youtube.com/watch?v=3pVif7iN8Lw
  https://www.youtube.com/watch?v=qzLH34P95uo
-->
<template>
  <div class="flex items-center relative" @mouseleave="showUnitList = false">

    <!--Here the logic for validity checking of unit:
        on each keyup, recalc filteredGroupsData, when the filteredGroupsData is empty, unit is not
        valid; however, only if onChange is triggered, the new unit input will be updated to the
        modelValue.

        The idea here is that the user can type in a unit and get accepted if the unit is valid.
        Also, the user can select a unit from the dropdown and get accepted.

        This seems to be doable.
     -->
    <!--
      unit: {{ selectedUnit }} <br>
      cat: {{ selectedCategory }} <br>
      fData.len: {{ filteredGroupsData.length }} <br>
    -->

    <div class="flex items-center max-w-md">

      <input type="text" name="query" autocomplete="off"
        class="input-pq0 text-center text-crimsontext-semibolditalic w-full z-10"
        :class="[{'text-blue-600': isUnitValid}, {'text-red-800 bg-yellow-200': !isUnitValid}]"
        v-model="searchTerm"
        @click="showUnitList = true"
        @keyup="filterGroupData(searchTerm); showUnitList = true"
        @keyup.esc="showUnitList = false"
        @change="acceptChange(searchTerm)"/>

      <div class="pl-1">
        <button class="btn-unitlist rounded-full hover:bg-blue-400 focus:outline-none w-6 h-6 flex items-center justify-center"
          @click.prevent="showCurrentCategory = !showCurrentCategory">
          <img v-if="showCurrentCategory" src="../assets/icons/keyboard_arrow_down_black_24dp.svg"
            alt="show current category"
            title="Units of current category. Click to show all categories."
            class="w-4 h-4"/>
          <img v-else src="../assets/icons/keyboard_double_arrow_down_black_24dp.svg"
            alt="show all categories"
            title="Units of all categories. Click to show current category."
            class="w-4 h-4"/>
        </button>
      </div>

    </div>

    <div class="absolute top-8 left-0 w-full rounded-md z-20 max-h-80 overflow-auto">
      <!-- choose from units of all categories with filter -->
      <div v-if="filteredGroupsData && showUnitList && !showCurrentCategory"
        class="w-full bg-gray-300 px-1" id="unit-list">
        <div v-for="(group, i) in filteredGroupsData" :key="group[groupKey]">  <!-- loop thru all categories/groups -->
          <div class="group-title text-center px-5 py-1 bg-red-200"> {{i}}-{{ group[groupKey] }}</div>   <!-- show category/group name -->
          <ul class="bg-gray-400 text-center text-white max-h-48 overflow-auto">
            <li v-for="(option, index) in group[groupOptionsKey]" :key="`option-${index}`"
              class="px-2 py-1 border-b cursor-pointer hover:text-white hover:bg-blue-500"
              @click="setOption(group[groupKey], option)"> {{ option }}
            </li>
          </ul>
        </div>
      </div>

      <!-- choose from units of the current category group -->
      <div v-else-if="categoryUnitData && showUnitList && showCurrentCategory"
        class="w-full bg-gray-300 px-1" id="unit-list">
        <div v-for="group in categoryUnitData" :key="group[groupKey]">
          <div class="group-title text-center px-5 py-1 bg-red-200">{{ group[groupKey] }}</div>
          <ul class="bg-gray-400 text-center text-white max-h-48 overflow-auto">
            <li v-for="(option, index) in group[groupOptionsKey]" :key="`option-${index}`"
              class="px-2 py-1 border-b cursor-pointer hover:text-white hover:bg-blue-500"
              @click="setOptionInCategory(option)"> {{ option }}
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: { // input of the unit. Refer to BaseInput.vue.
      type: String,
      default: ''
    },
    data: { type: Array },            // uomCategories == UomCategories
    groupKey: { type: String },       // 'name' key - to locate the category
    groupOptionsKey: { type: String } // 'units' key - to locate the list of units, eg: all units under 'LENGTH', like 'in', 'cm'
  },
  components: {},
  emits: ['update:modelValue', 'updateCategory', 'unitUpdated', 'acceptCustomUnit'],
  setup (props, cxt) {
    const searchTerm = ref(props.modelValue)

    // filtering all groups for the searchTerm, ie: units from all UomCategories that has the searchTerm.
    const filteredGroupsData = reactive([])

    /** All units in selected category, stored as array of single category data, so the same
     *  logic can be applied as to all categories
     */
    const categoryUnitData = reactive([]) // all data under UomCategory for current category
    const selectedUnit = ref('')
    const selectedCategory = ref('')
    const showUnitList = ref(false)
    const showCurrentCategory = ref(false)

    onMounted(() => {
      const mValue = props.modelValue
      let categoryData = {}

      filterGroupData(mValue)

      if (mValue !== '') { // if it receives a unit from existing calculation.
        if (filteredGroupsData.length > 0) { // mValue (unit input) is available in lib data
          selectedUnit.value = mValue
          categoryData = getCategoryData(mValue) // UomCategories data of current category
          categoryUnitData.splice(0, categoryUnitData.length)
          categoryUnitData.push(categoryData)
          selectedCategory.value = categoryData[props.groupKey]
          showCurrentCategory.value = true
        } else { // mValue is not available in lib data
          console.log(`${mValue} is not available in lib data.`)
        }
      }
    })

    /** check the current unit is available in lib data */
    const isUnitValid = computed(() => filteredGroupsData.length > 0)

    /** Only valid unit will get selected or accepted, and then need to have category for other uses
     *  get the category data (the same type as in uom.data's category) for the selected unit.
    */
    const getCategoryData = unit => {
      try {
        const unitCategoryData = props.data.find(groupData => {
          return groupData[props.groupOptionsKey].includes(unit)
        })
        if (unitCategoryData) {
          // console.log(`unitCategoryData@getCategoryData: ${unitCategoryData}`)
          return unitCategoryData
        } else {
          return { name: '', units: [] }
        }
      } catch (error) {
        console.error(`Error in getCategoryData: ${error}`)
      }
    }

    const filterGroupData = (term = '') => {
      filteredGroupsData.splice(0, filteredGroupsData.length)
      // Step 1: get all groups that match the search term
      const filteredDataInGroup = props.data.filter(groupData => {
        // console.log(`groupData.${props.groupKey}: ${groupData[props.groupKey]}`)
        return groupData[props.groupOptionsKey].some(
          unit => unit.toLowerCase().includes(term.toLowerCase())
        )
      })

      const filteredData = []

      // Step 2: get all units that match the search term in above filtered groups
      filteredDataInGroup.forEach(groupData => {
        const filteredOptions = groupData[props.groupOptionsKey].filter(
          unit => unit.toLowerCase().includes(term.toLowerCase())
        )

        const tempData = Object.assign({}, groupData, { [props.groupOptionsKey]: filteredOptions })

        return filteredData.push(tempData)
      })
      filteredGroupsData.push(...filteredData)
    }

    /**
     *
     * @param {*} category
     *
     */
    const getCategoryUnitData = category => {
      categoryUnitData.splice(0, categoryUnitData.length)
      const categoryData = props.data.find(groupData => groupData[props.groupKey] === category)
      categoryUnitData.push(categoryData) // all data under UomCategory for current category
    }

    const setOption = (category, option) => { // set the selected unit from the dropdown
      // const oldUnit = selectedUnit.value
      const oldCategory = selectedCategory.value
      let categoryChanged = false
      selectedUnit.value = option
      searchTerm.value = option

      debugger

      if (oldCategory !== category) { // if category is changed
        selectedCategory.value = category
        categoryChanged = true
        getCategoryUnitData(category)
      }
      cxt.emit('updateCategory', {changed: categoryChanged, category})

      cxt.emit('update:modelValue', option)
      cxt.emit('unitUpdated', option) // this comes after updateCategory, so unitCategoryChanged has been updated.

      showUnitList.value = false
      showCurrentCategory.value = true
    }

    const setOptionInCategory = option => { // set the selected unit from the dropdown
      selectedUnit.value = option
      searchTerm.value = option
      cxt.emit('update:modelValue', option)
      cxt.emit('unitUpdated', option)
      showUnitList.value = false
      // showCurrentCategory.value = true
    }

    const acceptChange = term => { // set the selected unit from the input, bypass the dropdown
      // const oldUnit = selectedUnit.value
      const oldCategory = selectedCategory.value

      if (term === '') {
        searchTerm.value = ''
        selectedUnit.value = ''
        selectedCategory.value = ''

        cxt.emit('update:modelValue', term)
        cxt.emit('unitUpdated', term)
        cxt.emit('updateCategory', {changed: false, category: ''})
        showUnitList.value = false
        showCurrentCategory.value = false
        return
      }

      if (filteredGroupsData.length) { // unit input exists in unit library
        const filteredCategoryData = filteredGroupsData.find(groupData => {
          // To get the category, we need to loop through filteredGroupsData
          // console.log(`groupData: ${groupData}`)
          return groupData[props.groupOptionsKey].includes(term)
          // For user input unit, it is case sensitive, but for the dropdown, it is not.
        })

        if (filteredCategoryData /* || term === '' */) { // TODO: Should I trim the term here to handle '   '?
          searchTerm.value = term
          selectedUnit.value = term

          const category = filteredCategoryData[props.groupKey] // 'Length'

          if (oldCategory !== category) { // if category is changed
            selectedCategory.value = category
            cxt.emit('updateCategory', {changed: true, category})
            getCategoryUnitData(category)
          }

          cxt.emit('update:modelValue', term)
          showUnitList.value = false
          showCurrentCategory.value = !!category
        }
        // else if (term === '') { // TODO: Should I trim the term here to handle '   '?
        //   searchTerm.value = term
        //   selectedUnit.value = term
        // }
      } else { // unit input does not exist in library
        /** Below is to be implemented with vuex / pinia, so user only needs to confirm when it is in auto mode, to allow user to switch to manual mode. */
        // if (unitConversionMode.value === 'Manual') {
        let isCustomUnit = confirm(`The unit input does not exist in the library. Accept it will
          disable automatic unit conversion. Confirm to accept it as a custom unit?`)
        // }

        if (isCustomUnit) {
          searchTerm.value = term
          selectedUnit.value = term
          cxt.emit('acceptCustomUnit')
          cxt.emit('update:modelValue', term)
          showUnitList.value = false
          showCurrentCategory.value = false
        }
      }
    }

    /* watch(searchTerm, () => {
      filterGroupData()
      isUnitValid.value = (filteredGroupsData.length > 0) // for class binding
      showUnitList.value = (searchTerm.value.length > 0)
    }) */

    // try to hide the list when user clicks outside of the list.
    // https://stackoverflow.com/questions/152975/how-do-i-detect-a-click-outside-an-element
    const hideList = e => {
      if (!e.target.closest('#unit-list')) showUnitList.value = false
    }

    return {
      searchTerm,
      selectedUnit,
      selectedCategory,
      showUnitList,
      isUnitValid,
      showCurrentCategory,
      filterGroupData,
      filteredGroupsData,
      getCategoryUnitData,
      categoryUnitData,
      setOption,
      setOptionInCategory,
      acceptChange,
      hideList
    }
  }

}
</script>

<style></style>
